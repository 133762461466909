import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import InputField from './shared/InputField'
import TextareaField from './shared/TextareaField'
import FileInputField from './shared/FileInputField'
import SelectField from './shared/SelectField'
import { trainings } from '../utils/trainings'
import moment from 'moment'
import { countries } from '../utils/countries'
const genders = [
  { label: '* Gender', value: '' },
  { label: 'Female', value: 'Female' },
  { label: 'Male', value: 'Male' },
  { label: 'Other', value: 'Other' },
]

const Edit = (props) => {
  const initialState = {
    firstName: '',
    lastName: '',
    country: '',
    company: '',
    mentor: '',
    profession: '',
    program: '',
    otherCourses: '',
    testimony: '',
    programTime: '',
    rating: '',
    gender: '',
    linkedIn: '',
    gitHub: '',
    fileUrl: '',
    file: '',
  }
  const [formData, setFormData] = useState(initialState)
  const [errors, setErrors] = useState({})
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    axios
      .get(`/api/testimonials/${id}`)
      .then((res) => {
        setFormData({
          ...formData,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          country: res.data.country,
          program: res.data.program,
          mentor: res.data.mentor,
          otherCourses: res.data.otherCourses.join(', '),
          programTime: res.data.programTime,
          company: res.data.company,
          profession: res.data.profession,
          testimony: res.data.testimony,
          rating: res.data.rating,
          gender: res.data.gender,
          linkedIn: res.data.linkedIn,
          gitHub: res.data.gitHub,
          fileUrl: res.data.imageUrl,
        })
      })
      .catch((err) => {
        setErrors(err.response.data)
      })
  }, [formData, id, navigate])

  const onChange = (e) => {
    const { name, value, type } = e.target
    setFormData({ ...formData, [name]: value })
    if (type === 'file') {
      const files = e.target.files
      setFormData({
        ...formData,
        file: files[0],
        fileUrl: URL.createObjectURL(e.target.files[0]),
      })
    }
    if (type === 'checkbox') {
      setFormData({ ...formData, [name]: e.target.checked })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const data = new FormData()
    for (const key in formData) {
      if (key === 'fileUrl') continue
      data.append(key, formData[key])
    }

    try {
      const response = await axios.put(`/api/testimonies/${id}`, data)
      if (response.status === 20) {
        navigate('/')
      }
    } catch (error) {
      console.log('Error during updating')
    }
    if (Object.keys(errors).length === 0) {
      setTimeout(() => {
        window.location.href = `/show-testimony/${id}`
      }, 1000)
    }
  }

  const programTime = moment(formData.programTime).format('YYYY-MM-DD')

  return (
    <div className='container'>
      <div className='row edit-testimony-header'>
        <div>
          <Link to='/' className='btn'>
            <i className='fas fa-chevron-left'></i> Testimonies
          </Link>
        </div>
        <div>
          <h1>Edit Testimony</h1>
        </div>
      </div>
      <form
        noValidate
        onSubmit={onSubmit}>
        <InputField
          placeholder='First Name'
          name='firstName'
          className={`form-control ` + errors.firstName ? 'error' : 'success'}
          value={formData.firstName}
          onChange={onChange}
          error={errors.firstName}
        />
        <InputField
          id='lastName'
          name='lastName'
          value={formData.lastName}
          onChange={onChange}
          placeholder='Last Name'
          error={errors.lastName}
        />
        <SelectField
          id='gender'
          name='gender'
          value={formData.gender}
          onChange={onChange}
          options={genders}
          error={errors.gender}
        />
        <SelectField
          id='country'
          name='country'
          value={formData.country}
          onChange={onChange}
          options={countries}
          error={errors.country}
        />
        <InputField
          placeholder='Profession'
          name='profession'
          className='form-control'
          value={formData.profession}
          onChange={onChange}
          error={errors.profession}
        />
        <InputField
          placeholder='company'
          name='company'
          className='form-control'
          value={formData.company}
          onChange={onChange}
          error={errors.company}
        />
        <SelectField
          id='program'
          name='program'
          value={formData.program}
          onChange={onChange}
          options={trainings}
          error={errors.program}
        />
        <TextareaField
          label='Other courses or programs you took from Asabeneh'
          id='otherCourses'
          name='otherCourses'
          value={formData.otherCourses}
          onChange={onChange}
          placeholder='Programs or course separated by comma(e.g HTML, CSS, JS, React, Python)'
          cols='80'
          error={errors.otherCourses}
        />
        <InputField
          label='When did you start the program ?'
          type='date'
          name='programTime'
          className='form-control'
          value={programTime}
          onChange={onChange}
        />
        <TextareaField
          label='Testimonial'
          id='testimony'
          name='testimony'
          value={formData.testimony}
          onChange={onChange}
          placeholder='What did you expect and how did the program help you in your career...'
        />
        <InputField
          type='number'
          name='rating'
          className='form-control'
          value={formData.rating}
          onChange={onChange}
        />
        <InputField
          id='linkedIn'
          name='linkedIn'
          value={formData.linkedIn}
          onChange={onChange}
          placeholder='LinkedIn account link (e.g https://www.linkedin.com/in/asabeneh/)'
        />
        <InputField
          id='gitHub'
          name='gitHub'
          value={formData.gitHub}
          onChange={onChange}
          placeholder='GitHub account link (e.g https://github.com/Asabeneh)'
        />
        <InputField
          label='Have you been mentoring?'
          type='checkbox'
          name='mentor'
          className='form-control'
          onChange={onChange}
        />
        <FileInputField
          name='file'
          id='file'
          onChange={onChange}
          formData={formData}
          text='Upload Image'
        />
        {formData.fileUrl !== '' && (
          <div>
            <img
              src={formData.fileUrl}
              alt={formData.file.name}
              className='image-preview'
            />
          </div>
        )}
        <button type='submit' className='btn'>
          Save
        </button>{' '}
        <Link to={`/testimony/${id}`} className='btn'>
          Cancel
        </Link>
      </form>
    </div>
  )
}

export default Edit
