import React from "react"
import styles from "./input-file.module.scss"

const FileInputField = ({
  onChange,
  formData,
  percentage,
  text,
  id,
  name,
  placeholder,
  error,
}) => {
  let fileName =
    formData.file === "" || formData === undefined ? text : formData.file.name
  return (
    <div className='form-group file-upload'>
      <input
        className={styles.inputfile}
        type='file'
        name={name}
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        accept='image/*'
        /*      accept='file_extension|audio/*|video/*|image/*|media_type' */
        size='600'
      />
      <label htmlFor={id} id={id}>
        {' '}
        <i className='fas fa-upload'></i>
        {fileName}
      </label>
      {/* {formData.image && (
        <ProgressBar bgcolor={'#6a1b9a'} completed={percentage} />
      )} */}
      <br />
      {error && <small style={{ color: 'red' }}>{error}</small>}
    </div>
  )
}

export default FileInputField
