const Rate = ({ rating }) => {
  let result
  if (rating === 1) {
    result = (
      <div className='stars'>
        <i className='fas fa-star'></i>
        <i className='far fa-star'></i>
        <i className='far fa-star'></i>
        <i className='far fa-star'></i>
        <i className='far fa-star'></i>
      </div>
    )
  } else if (rating === 2) {
    result = (
      <div className='stars'>
        <i className='fas fa-star'></i>
        <i className='fas fa-star'></i>
        <i className='far fa-star'></i>
        <i className='far fa-star'></i>
        <i className='far fa-star'></i>
      </div>
    )
  } else if (rating === 3) {
    result = (
      <div className='stars'>
        <i className='fas fa-star'></i>
        <i className='fas fa-star'></i>
        <i className='fas fa-star'></i>
        <i className='far fa-star'></i>
        <i className='far fa-star'></i>
      </div>
    )
  } else if (rating === 4) {
    result = (
      <div className='stars'>
        <i className='fas fa-star'></i>
        <i className='fas fa-star'></i>
        <i className='fas fa-star'></i>
        <i className='fas fa-star'></i>
        <i className='far fa-star'></i>
      </div>
    )
  } else if (rating === 5) {
    result = (
      <div className='stars'>
        <i className='fas fa-star'></i>
        <i className='fas fa-star'></i>
        <i className='fas fa-star'></i>
        <i className='fas fa-star'></i>
        <i className='fas fa-star'></i>
      </div>
    )
  }

  return result ? result : null
}

export default Rate
