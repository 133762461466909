import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import SocialLinks from './SocialLinks'
import useFetch from './useFetch'
import Rate from './Rate'
import { formatCountry } from '../utils/format-country'

const Details = (props) => {
  const [item, setItem] = useState({})
  const [items, setItems] = useState([])
  const [index, setIndex] = useState(0)
  const navigate = useNavigate()
  const url = 'https://restcountries.com/v2/all'
  const countries = useFetch(url)
  let { id } = useParams()
  const itemIndex = items.findIndex((itm) => item._id === id)
  useEffect(() => {
    axios
      .get(`/api/testimonials/${id}`)
      .then((res) => {
        setItem(res.data)
      })
      .catch((err) => {
        console.log('Error from single detail')
      })
  }, [id])

  useEffect(() => {
    axios
      .get('/api/testimonials')
      .then((response) => {
        setItems(response.data)
      })
      .catch((err) => {
        console.log('Error form')
      })
  }, [])
  const deleteItem = (id) => {
    axios
      .delete(`/api/testimonials/${id}`)
      .then((res) => {
        navigate('/')
      })
      .catch((err) => {
        console.log('Error from book details delete')
      })
  }
  const nextItem = () => {
    if (itemIndex < items.length) {
      setIndex(index + 1)
    }
  }
  let formattedCountry = formatCountry(item.country)

  let detailItem = (
    <section className='details'>
      {/* <div>
        <i className='fas fa-chevron-left'></i>
      </div> */}
      <div className='detail-left'>
        <div className='person-image'>
          <img src={item.imageUrl} alt={item.firstName} loading='lazy' />
        </div>

        <div className='profile-social-links'>
          <SocialLinks
            linkedIn={item.linkedIn}
            gitHub={item.gitHub}
            mentor={item.mentor}
          />
        </div>
        <div className='person-basic-info'>
          <h3 className='person-name'>
            {item.gender === 'Male' ? (
              <i className='fas fa-male gender-icon'></i>
            ) : (
              <i className='fas fa-female gender-icon'></i>
            )}{' '}
            {}
            {item.firstName?.toUpperCase()} {item.lastName?.toUpperCase()}
          </h3>
          <h3 className='profession'>{item.profession}</h3>

          <div className='flag-wrapper'>
            <img
              src={countries.find((c) => c.name === item.country)?.flag}
              alt={item.country}
              loading='lazy'
              className='flag-image'
            />
            {formattedCountry && <small>{formattedCountry}</small>}
            {item.company && (
              <>
                <small>,</small>
                <small className='company'> &nbsp;{item.company}</small>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='card-body desc detail-right'>
        <h1 style={{ margin: '15px auto', fontSize: '22px', fontWeight: 500 }}>
          {item.program}
        </h1>
        <blockquote>
          <i className='fas fa-quote-left'></i>
          <p style={{ fontSize: 13 }}>{item.testimony}</p>
          <i className='fas fa-quote-right'></i>
        </blockquote>
        <Rate rating={item?.rating} />
      </div>
      {/* <div>
        <i
          className='fas fa-chevron-right'
          onClick={() => nextItem()}></i>
      </div> */}
    </section>
  )
  const isLoaded = Object.keys(item).length > 1

  return isLoaded ? (
    <>
      <div className='details-header-link'>
        <Link to='/' title='Go testimonials'>
          {/* <i className='fas fa-chevron-left'></i>  */}
          {/* <i className="fas fa-solid fa-grip-vertical"></i> */}
          <i className='fas fa-solid fa-grip-horizontal'></i>
        </Link>
        {detailItem}
      </div>

      {item.ip === item.currentIp && (
        <div className='details-footer'>
          <Link
            to={`/edit-testimony/${id}`}
            className='btn action-button btn-edit'
          >
            <i className='fas fa-edit'></i>
          </Link>{' '}
          <button
            type='button'
            className='btn action-button btn-delete'
            onClick={() => deleteItem(id)}
          >
            <i className='fas fa-trash-alt'></i>
          </button>
        </div>
      )}

     {/*  <div className='details-footer'>
              <Link to={`/testimony/edit/${id}`} className="btn action-button btn-edit">
                    <i class="fas fa-edit"></i>
              </Link>
           {' '}
              <button type="button" className="btn action-button btn-delete" onClick={() => deleteItem(id)}><i class="fas fa-trash-alt"></i></button>
          </div>  */}
    </>
  ) : (
    <>
      <div className='details-middle-row spinner'>
        <i className='fas fa-solid fa-spinner fa-spin'></i>
      </div>
    </>
  )
}

export default Details
