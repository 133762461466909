import React from "react"
import PropTypes from "prop-types"

const SelectField = ({
  options,
  label,
  name,
  value,
  onChange,
  className,
  error
}) => {
  const optionList = options.map((option) => (
    <option
      key={option.value}
      value={option.value}>
      {option.label}
    </option>
  ))
  return (
    <div className={className ? className : "form-group select-options"}>
      {label ? <label>{label}: </label> : ""}
      <select
        name={name}
        value={value}
        onChange={onChange}>
        {optionList}
      </select>
      {error && <small style={{ color: "red" }}>{error}</small>}
    </div>
  )
}

SelectField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default SelectField
